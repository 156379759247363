/*
    Flaticon icon font: Flaticon
    Creation date: 08/09/2018 00:19
    */

@font-face {
  font-family: "Flaticon";
  src: url("../fonts/Flaticon.eot");
  src: url("../fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
       url("../fonts/Flaticon.woff") format("woff"),
       url("../fonts/Flaticon.ttf") format("truetype"),
       url("../fonts/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.flaticon-medal:before { content: "\f100"; }
.flaticon-certification:before { content: "\f101"; }
.flaticon-trophy:before { content: "\f102"; }
.flaticon-award-star-with-olive-branches:before { content: "\f103"; }
.flaticon-award-medal-of-number-one-with-olive-branches:before { content: "\f104"; }
.flaticon-cogwheel:before { content: "\f105"; }
.flaticon-customer-service:before { content: "\f106"; }
.flaticon-maintenance:before { content: "\f107"; }
.flaticon-cloud-computing:before { content: "\f108"; }
.flaticon-cloud-computing-1:before { content: "\f109"; }
.flaticon-cloud-storage:before { content: "\f10a"; }
.flaticon-cyber-security:before { content: "\f10b"; }
.flaticon-cyber-security-1:before { content: "\f10c"; }
.flaticon-globe-grid:before { content: "\f10d"; }
.flaticon-backup:before { content: "\f10e"; }
.flaticon-backup-1:before { content: "\f10f"; }
.flaticon-backup-2:before { content: "\f110"; }
.flaticon-select:before { content: "\f111"; }
.flaticon-technical-support:before { content: "\f112"; }
.flaticon-tools:before { content: "\f113"; }
.flaticon-interview:before { content: "\f114"; }
.flaticon-meeting:before { content: "\f115"; }
.flaticon-video-call:before { content: "\f116"; }
.flaticon-plan:before { content: "\f117"; }
.flaticon-strategy:before { content: "\f118"; }
.flaticon-sketch:before { content: "\f119"; }
.flaticon-help:before { content: "\f11a"; }
.flaticon-gear:before { content: "\f11b"; }
.flaticon-secure-data:before { content: "\f11c"; }
.flaticon-secure:before { content: "\f11d"; }
.flaticon-firewall:before { content: "\f11e"; }
.flaticon-firewall-1:before { content: "\f11f"; }
.flaticon-firewall-2:before { content: "\f120"; }
.flaticon-implement:before { content: "\f121"; }
.flaticon-analytics:before { content: "\f122"; }
.flaticon-analysis-1:before { content: "\f123"; }
.flaticon-research:before { content: "\f124"; }
.flaticon-reduction:before { content: "\f125"; }
.flaticon-risk:before { content: "\f126"; }
.flaticon-location:before { content: "\f127"; }
.flaticon-pin:before { content: "\f128"; }
.flaticon-email-1:before { content: "\f129"; }
.flaticon-email:before { content: "\f12a"; }
.flaticon-call:before { content: "\f12b"; }
.flaticon-telephone:before { content: "\f12c"; }
.flaticon-apple:before { content: "\f12d"; }
.flaticon-apple-1:before { content: "\f12e"; }
.flaticon-google-play:before { content: "\f12f"; }
.flaticon-google-play-1:before { content: "\f130"; }