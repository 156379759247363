.backend-layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .d-flex {
    display: flex;
  }
  
  .content-container {
    padding: 20px;
    background-color: #f8f9fa; /* Content background color */
    flex-grow: 1;
  }
  
  /* .content {
    min-height: calc(100vh - 80px); Adjust based on footer height
  } */
  
  footer {
    margin-top: auto;
  }
  