/* Custom styles for the Dashboard */

.bg-light-grey {
    background-color: #f5f5f5; /* Light grey background */
  }
  
  .sidebar {
    width: 250px;
    min-height: 100vh;
  }
  
  .sidebar-content {
    padding: 15px;
  }
  
  .navbar {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .footer {
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
  }
  