/* Navbar Bootstrap */

@media (min-width:768px) {
  .nav-box .container, 
  .nav-box .container-lg, 
  .nav-box .container-md, 
  .nav-box .container-sm, 
  .nav-box .container-xl {
    max-width: 720px;
    width: 720px;
  }
}

@media (min-width:992px) {
  .nav-box .container, 
  .nav-box .container-lg, 
  .nav-box .container-md, 
  .nav-box .container-sm, 
  .nav-box .container-xl {
    max-width: 960px;
    width: 960px;
  }
}

@media (min-width:1200px) {
  .nav-box .container, 
  .nav-box .container-lg, 
  .nav-box .container-md, 
  .nav-box .container-sm, 
  .nav-box .container-xl {
    max-width: 1140px;
    width: 1140px;
  }
}

.navbar .container, 
.navbar .container-fluid, 
.navbar .container-lg, 
.navbar .container-md, 
.navbar .container-sm, 
.navbar .container-xl {
  display: block;
}

@media (min-width: 768px) {
  .navbar .navbar-right .dropdown-menu {
    right: 0;
    left: auto;
  }

  .navbar .navbar-right .dropdown-menu-left {
    right: auto;
    left: 0;
  }
}

header .container-full {
  position: relative;
  padding: 0;
}

nav.navbar.bootsnav.nav-box .navbar-brand {
  padding-left: 0;
}

.navbar {
  display: block;
  position: relative;
  min-height: 50px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  padding: 0;
}

.navbar-nav .dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin-top: 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, .15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
}

.navbar .navbar-collapse.collapse {
  display: block !important;
  height: auto !important;
  padding-bottom: 0;
  overflow: visible !important;
  flex: inherit;
}

.dropdown-toggle::after {
  display: inline;
  margin: 0;
  border: none;
  vertical-align: inherit;
}

.nav {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  display: block;
}

.nav > li {
  position: relative;
  display: block;
}

.nav > li > a {
  position: relative;
  display: block;
  padding: 10px 15px;
}

.nav > li > a:hover,
.nav > li > a:focus {
  text-decoration: none;
  background-color: #eee;
}

.nav > li.disabled > a {
  color: #777;
}

.nav > li.disabled > a:hover,
.nav > li.disabled > a:focus {
  color: #777;
  text-decoration: none;
  cursor: not-allowed;
  background-color: transparent;
}

.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
  background-color: #eee;
  border-color: #337ab7;
}

.nav .nav-divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: #e5e5e5;
}

.nav > li > a > img {
  max-width: none;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.navbar {
  position: relative;
  min-height: 50px;
  margin-bottom: 20px;
  border: 1px solid transparent;
}

@media (min-width: 768px) {
  .navbar {
    border-radius: 4px;
  }
}

@media (min-width: 768px) {
  .navbar-header {
    float: left;
  }
}

.navbar .attr-nav form {
  border: 1px solid #e7e7e7;
  position: relative;
  margin: 14px 0;
  margin-left: 10px;
  min-width: 250px;
  border-radius: 30px;
}

.navbar .attr-nav form button {
  position: absolute;
  right: 2px;
  top: 2px;
  height: 46px;
  width: 46px;
  background: #086AD8;
  color: #ffffff;
  border-radius: 50%;
}

.navbar .attr-nav .contact {
  display: flex;
  align-items: center;
  padding: 25px 0;
}

.navbar .attr-nav .contact strong {
  display: block;
  color: #0e2b3d;
  font-weight: 700;
}

.navbar.bg-theme .attr-nav .contact strong {
  color: #ffffff;
}

.navbar .attr-nav .contact i {
  font-size: 40px;
  margin-right: 15px;
  padding-right: 15px;
  border-right: 1px solid #e7e7e7;
}

.navbar.bg-theme .attr-nav .contact i {
  border-right: 1px solid rgba(255, 255, 255, 0.4);
}

.navbar .attr-nav .contact p {
  margin: 0;
  font-size: 15px;
  line-height: 25px;
}

.navbar.bg-theme .attr-nav .contact p {
  color: #ffffff;
}

.navbar .attr-nav form input {
  border: none;
  box-shadow: inherit;
  border-radius: 30px;
  padding-left: 20px;
}

.navbar-brand {
  float: left;
  height: 50px;
  padding: 15px 15px;
  font-size: 18px;
  line-height: 20px;
}

.navbar-brand:hover,
.navbar-brand:focus {
  text-decoration: none;
}

.navbar-brand > img {
  display: block;
}

@media (min-width: 768px) {
  .navbar > .container .navbar-brand,
  .navbar > .container-fluid .navbar-brand {
    margin-left: -15px;
  }
}

@media only screen and (max-width: 1199px) {
  .navbar .attr-nav .contact {
    display: none;
  }
}

.navbar-toggle {
  position: relative;
  float: right;
  padding: 9px 10px;
  margin-top: 8px;
  margin-right: 15px;
  margin-bottom: 8px;
  background-color: transparent;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}

.navbar-toggle:focus {
  outline: 0;
}

.navbar-toggle .icon-bar {
  display: block;
  width: 22px;
  height: 2px;
  border-radius: 1px;
}

.navbar-toggle .icon-bar + .icon-bar {
  margin-top: 4px;
}

@media (min-width: 768px) {
  .navbar-toggle {
    display: none;
  }
}

.navbar-nav {
  margin: 7.5px -15px;
}

.navbar-nav > li > a {
  padding-top: 10px;
  padding-bottom: 10px;
  line-height: 20px;
}

@media (max-width: 767px) {
  .navbar-nav .open .dropdown-menu {
    position: static;
    float: none;
    width: auto;
    margin-top: 0;
    background-color: transparent;
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .navbar-nav .open .dropdown-menu > li > a,
  .navbar-nav .open .dropdown-menu .dropdown-header {
    padding: 5px 15px 5px 25px;
  }

  .navbar-nav .open .dropdown-menu > li > a {
    line-height: 20px;
  }

  .navbar-nav .open .dropdown-menu > li > a:hover,
  .navbar-nav .open .dropdown-menu > li > a:focus {
    background-image: none;
  }
}

@media (min-width: 768px) {
  .navbar-nav {
    float: left;
    margin: 0;
  }

  .navbar-nav > li {
    float: left;
  }

  .navbar-nav > li > a {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

@media (min-width: 768px) {
  .navbar-left {
    float: left !important;
  }

  .navbar-right {
    float: right !important;
    margin-right: -15px;
  }

  .navbar-right ~ .navbar-right {
    margin-right: 0;
  }
}

.dropup .dropdown-menu,
.navbar-fixed-bottom .dropdown .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-bottom: 2px;
}

.nav:before,
.nav:after,
.navbar:before,
.navbar:after,
.navbar-header:before,
.navbar-header:after,
.navbar-collapse:before,
.navbar-collapse:after {
  display: table;
  content: " ";
}

.nav:after,
.navbar:after,
.navbar-header:after,
.navbar-collapse:after {
  clear: both;
}

/* * Navbar Main Css * v1.0 */
nav.bootsnav .dropdown.megamenu-fw {
  position: static;
}

nav.bootsnav .container {
  position: relative;
}

nav.bootsnav .megamenu-fw .dropdown-menu {
  left: auto;
}

nav.bootsnav .megamenu-content {
  padding: 15px;
  width: 100% !important;
}

nav.bootsnav.nav-box .container {
  position: relative;
  padding: 0 30px;
}

nav.bootsnav.nav-box .dropdown-menu {
  left: -15px;
}

nav.bootsnav .megamenu-content .title {
  color: #333333;
  font-weight: 600;
  margin-top: 0;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-size: 14px;
}

nav.bootsnav .dropdown.megamenu-fw .dropdown-menu {
  left: 0;
  right: 0;
}

.navbar .side .widget > a {
  border-radius: 30px;
}

.navbar .side .widget .contact li {
  display: flex;
  margin-bottom: 25px;
}

.navbar .side .widget .contact li:last-child {
  margin-bottom: 0;
}

.navbar .side .widget .contact li .icon {
  padding-right: 15px;
}

.navbar .side .widget .contact li .icon, 
.navbar .side .widget .contact li .info {
  display: table-cell;
  vertical-align: middle;
}

.navbar .side .widget .contact li .icon i {
  color: #086AD8;
  font-size: 30px;
  display: inline-block;
  text-align: center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  font-weight: 500;
}

.navbar .side .widget .contact li .icon i.fa-phone {
  font-weight: 600;
}

.navbar .side .widget .contact li .info span {
  display: block;
  font-family: 'Inter', sans-serif;
  font-weight: 900;
  line-height: 20px;
  text-transform: uppercase;
  font-size: 14px;
  width: 100%;
  margin-bottom: 5px;
  color: #0e2b3d;
}

.navbar-collapse {
  padding: 0;
}

nav.navbar .quote-btn a {
  color: #0e2b3d;
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  border: 2px solid #086AD8;
  text-transform: uppercase;
  top: -2px;
  letter-spacing: 0.6px;
}

nav.navbar .quote-btn {
  margin-top: -4px;
}

/* Navbar Default */
nav.navbar .navbar-brand {
  height: auto !important;
}

nav.navbar.bootsnav.logo-less .navbar-brand {
  display: none;
}

nav.navbar.navbar-default.logo-less .navbar-collapse {
  margin-left: -15px;
}

nav.navbar.bootsnav.navbar-default.navbar-fixed.navbar-transparent.inc-topbar {
  margin-top: 63px;
  transition: all 0.35s ease-in-out 0s;
}

nav.navbar.bootsnav.navbar-default.navbar-fixed.inc-topbar {
  margin-top: 0;
}

.navbar-default.navbar.navbar-sidebar.bg-dark .social-share {
  padding: 0 30px;
}

nav.bootsnav.navbar-sidebar.bg-dark ul.nav li.dropdown ul.dropdown-menu li a, 
nav.bootsnav.navbar-sidebar.bg-dark ul.nav li.dropdown a, 
nav.bootsnav.navbar-sidebar.bg-dark ul.nav li.dropdown h6 {
  color: #cccccc;
}

.attr-nav ul.cart-list li.total a {
  background: #086AD8 none repeat scroll 0 0;
  display: inline-block;
  font-weight: 600 !important;
  padding: 12px 15px;
  color: #ffffff !important;
}

.attr-nav ul.cart-list li.total a:hover {
  background: #086AD8 none repeat scroll 0 0 !important;
  color: #ffffff !important;
}

nav.navbar.bootsnav {
  background-color: #fff;
  border-radius: 0;
  border: none;
  box-shadow: 0 8px 25px 0 rgba(0, 0, 0, 0.04);
  -moz-box-shadow: 0 8px 25px 0 rgba(0, 0, 0, 0.04);
  -webkit-box-shadow: 0 8px 25px 0 rgba(0, 0, 0, 0.04);
  -o-box-shadow: 0 8px 25px 0 rgba(0, 0, 0, 0.04);
  margin: 0;
}

nav.bg-dark.navbar.bootsnav {
  background-color: #1c1c1c;
  border: medium none !important;
  margin: 0;
}

nav.navbar.bootsnav.bg-theme .logo.responsive {
  display: none;
}

nav.navbar.bootsnav ul.nav > li > a {
  color: #333333;
  background-color: transparent !important;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
}

nav.navbar.bootsnav.bg-dark ul.nav > li > a {
  color: #ffffff;
}

nav.navbar.bootsnav ul.nav li.megamenu-fw > a:hover, 
nav.navbar.bootsnav ul.nav li.megamenu-fw > a:focus, 
nav.navbar.bootsnav ul.nav li.active > a:hover, 
nav.navbar.bootsnav ul.nav li.active > a:focus, 
nav.navbar.bootsnav ul.nav li.active > a {
  background-color: transparent;
}

nav.navbar.bootsnav .navbar-toggle {
  background-color: transparent !important;
  border: none;
  padding: 0;
  font-size: 18px;
  position: relative;
  top: 5px;
}

nav.navbar.bootsnav ul.nav .dropdown-menu .dropdown-menu {
  top: 0;
  left: 100%;
}

nav.navbar.bootsnav ul.nav ul.dropdown-menu > li > a {
  white-space: normal;
}

ul.menu-col {
  padding: 0;
  margin: 0;
  list-style: none;
}

ul.menu-col li a {
  color: #333333;
}

ul.menu-col li a:hover, ul.menu-col li a:focus {
  text-decoration: none;
}

#navbar-menu {
  -webkit-animation-duration: .3s;
  animation-duration: .3s;
}

nav.bootsnav.navbar-full {
  padding-bottom: 10px;
  padding-top: 10px;
}

nav.bootsnav.navbar-full .navbar-header {
  display: block;
  width: 100%;
}

nav.bootsnav.navbar-full .navbar-toggle {
  display: inline-block;
  margin-right: 0;
  position: relative;
  top: 20px;
  font-size: 24px;
  -webkit-transition: all 1s ease-in-out;
  -moz-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  -ms-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
}

nav.bootsnav.navbar-full .navbar-collapse {
  position: fixed;
  width: 100%;
  height: 100% !important;
  top: 0;
  left: 0;
  padding: 0;
  display: none !important;
  z-index: 9;
}

nav.bootsnav.navbar-full .navbar-collapse.in,
nav.bootsnav.navbar-full .navbar-collapse.show {
  display: block !important;
}

nav.bootsnav.navbar-full .navbar-collapse .nav-full {
  overflow: auto;
}

nav.bootsnav.navbar-full .navbar-collapse .wrap-full-menu {
  display: table-cell;
  vertical-align: middle;
  background-color: #fff;
  overflow: auto;
}

nav.bootsnav.navbar-full .navbar-collapse .nav-full::-webkit-scrollbar {
  width: 0;
}

nav.bootsnav.navbar-full .navbar-collapse .nav-full::-moz-scrollbar {
  width: 0;
}

nav.bootsnav.navbar-full .navbar-collapse .nav-full::-ms-scrollbar {
  width: 0;
}

nav.bootsnav.navbar-full .navbar-collapse .nav-full::-o-scrollbar {
  width: 0;
}

nav.bootsnav.navbar-full .navbar-collapse ul.nav {
  display: block;
  width: 100%;
  overflow: auto;
}

nav.bootsnav.navbar-full .navbar-collapse ul.nav a:hover, 
nav.bootsnav.navbar-full .navbar-collapse ul.nav a:focus, 
nav.bootsnav.navbar-full .navbar-collapse ul.nav a {
  background-color: transparent;
}

nav.bootsnav.navbar-full .navbar-collapse ul.nav > li {
  float: none;
  display: block;
  text-align: center;
}

nav.bootsnav.navbar-full .navbar-collapse ul.nav > li > a {
  display: table;
  margin: auto;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: normal;
  font-size: 14px;
  padding: 15px 15px;
}

nav.navbar.bootsnav li.dropdown ul.dropdown-menu > li > a .badge {
  padding: 3px 10px;
  font-weight: 600;
  margin-left: 4px;
  background: #086AD8;
  color: #ffffff;
  border-radius: 5px;
}

nav.bootsnav.navbar-full .navbar-collapse ul.nav > li > a:hover {
  letter-spacing: 7px;
}

nav.bootsnav.navbar-full .navbar-collapse ul.nav > li.close-full-menu > a:hover {
  letter-spacing: normal;
}

li.close-full-menu > a {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

li.close-full-menu > a i {
  font-size: 32px;
}

li.close-full-menu {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.attr-nav {
  display: inline-block;
  float: right;
  margin-left: 30px;
}

nav.navbar.attr-border .attr-nav {
  margin-left: 30px;
  padding-left: 15px;
  min-height: 90px;
}

.bootsnav .side .widget.social li.facebook a {
  background: #3b5998 none repeat scroll 0 0;
}

.bootsnav .side .widget.social li.twitter a {
  background: #1da1f2 none repeat scroll 0 0;
}

.bootsnav .side .widget.social li.pinterest a {
  background: #bd081c none repeat scroll 0 0;
}

.bootsnav .side .widget.social li.g-plus a {
  background: #db4437 none repeat scroll 0 0;
}

.bootsnav .side .widget.social li.linkedin a {
  background: #0077b5 none repeat scroll 0 0;
}

.bootsnav .side .widget.social li.dribbble a {
  background: #ea4c89 none repeat scroll 0 0;
}

nav.navbar.attr-border.bootsnav.sticked .attr-nav {
  min-height: 80px;
}

.attr-nav > ul {
  padding: 0;
  margin: 0 0 -17px 0;
  list-style: none;
  display: inline-block;
}

nav.navbar.attr-border .attr-nav {
  margin-left: 15px;
  min-height: 90px;
  padding-left: 10px;
  position: relative;
  z-index: 1;
}

nav.navbar.attr-border .attr-nav::before {
  background: #e7e7e7 none repeat scroll 0 0;
  content: "";
  height: 30px;
  left: 0;
  margin-top: -15px;
  position: absolute;
  top: 50%;
  width: 1px;
}

.attr-nav > ul li.dropdown ul.dropdown-menu {
  margin-top: 0;
}

.attr-nav > ul > li {
  float: left;
  display: block;
}

.attr-nav > ul > li > a {
  color: #333333;
  display: block;
  padding: 32px 0 !important;
  position: relative;
}

.attr-nav > a {
  margin-top: 25px;
}

.attr-nav > ul > li > a span.badge {
  background-color: #086AD8;
  color: #ffffff;
  height: 20px;
  line-height: 20px;
  margin-top: -5px;
  padding: 0;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 20px;
}

.color-yellow .attr-nav > ul > li > a span.badge {
  background-color: #ff9800;
}

.attr-nav > ul > li.dropdown ul.dropdown-menu {
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  -o-border-radius: 0px;
  border-radius: 0px;
  -moz-box-shadow: 0px 0px 0px;
  -webkit-box-shadow: 0px 0px 0px;
  -o-box-shadow: 0px 0px 0px;
  box-shadow: 0px 0px 0px;
  border: solid 1px #e0e0e0;
}

ul.cart-list {
  padding: 0 !important;
  width: 250px !important;
}

ul.cart-list > li {
  position: relative;
  border-bottom: solid 1px #efefef;
  padding: 15px 15px 23px 15px !important;
}

ul.cart-list > li > a.photo {
  padding: 0 !important;
  margin-right: 15px;
  float: left;
  display: block;
  width: 50px;
  height: 50px;
  left: 15px;
  top: 15px;
}

ul.cart-list > li img {
  border: 1px solid #e7e7e7;
  height: 50px;
  padding: 3px;
  width: 50px;
}

ul.cart-list > li > h6 {
  margin: 0;
  font-size: 14px;
}

ul.cart-list > li > h6 > a.photo {
  padding: 0 !important;
  display: block;
}

ul.cart-list > li > p {
  margin-bottom: 0;
}

ul.cart-list > li.total {
  background-color: #f5f5f5;
  padding-bottom: 15px !important;
  font-family: 'Inter', sans-serif;
}

ul.cart-list > li.total > .btn {
  display: inline-block;
  border: none !important;
  height: auto !important;
}

ul.cart-list > li .price {
  font-family: 'Inter', sans-serif;
  font-weight: bold;
}

ul.cart-list > li .price:hover {
  box-shadow: inherit;
}

ul.cart-list > li.total > span {
  padding-top: 8px;
}

.navbar.bg-dark .top-search {
  background: #086AD8 none repeat scroll 0 0;
}

.top-search form {
  overflow: hidden;
  position: relative;
}

.top-search form button {
  background: transparent none repeat scroll 0 0;
  border: medium none;
  box-shadow: inherit;
  color: #666666;
  height: 50px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 50px;
  z-index: 9;
}

.top-search {
  background-color: #ffffff;
  border: medium none;
  border-radius: 30px;
  box-shadow: 0 10px 40px -15px rgba(0, 0, 0, 0.5);
  display: none;
  height: 50px;
  position: absolute;
  right: 10px;
  top: 90px;
  z-index: 99;
  border: 1px solid #e7e7e7;
}

nav.bootsnav.navbar-default.small-pad .top-search {
  top: 90px;
}

nav.bootsnav.navbar-default.small-pad.logo-less .top-search {
  top: 75px;
}

nav.bootsnav.navbar-default.nav-box .top-search {
  right: 30px;
}

.top-search input.form-control {
  background-color: transparent;
  border: medium none !important;
  box-shadow: inherit;
  color: #1c1c1c;
  min-width: 300px;
  padding: 0 20px;
}

.top-search .input-group-addon {
  background-color: transparent;
  border: medium none;
  color: #666666;
  padding-left: 0;
  padding-right: 0;
  position: absolute;
  right: 20px;
  top: 10px;
  z-index: 9;
}

.top-search .input-group-addon.close-search {
  cursor: pointer;
}

body {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.side {
  position: fixed;
  overflow-y: auto;
  top: 0;
  right: -400px;
  width: 400px;
  padding: 50px;
  height: 100%;
  display: block;
  background-color: #ffffff;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  z-index: 9;
  box-shadow: 0 15px 40px -5px rgba(0, 0, 0, 0.1);
}

.side.on {
  right: -16px;
}

.body-overlay {
  background: rgba(0, 0, 0, 0.65) none repeat scroll 0 0;
  bottom: 0;
  height: 0;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  transition: opacity 0.4s ease 0.8s, height 0s ease 1.2s;
  z-index: 490;
}

body.on-side .body-overlay {
  height: 100%;
  transition: height 0s ease 0s, opacity 0.4s ease 0s;
}

body.on-side .body-overlay {
  opacity: 1;
  top: 0;
}

.side .close-side {
  border-radius: 50%;
  color: #f44336;
  float: right;
  font-size: 20px;
  font-weight: 400;
  height: 40px;
  line-height: 38px;
  position: relative;
  text-align: center;
  top: -30px;
  width: 40px;
  z-index: 2;
}

.color-yellow .side .close-side {
  color: #ff9800;
}

.side.barber .close-side {
  border-color: #bc9355;
  color: #bc9355;
}

.navbar .side .widget.social li {
  display: inline-block;
}

.navbar .side .widget li a {
  color: #0e2b3d;
}

.navbar .side .widget li a:hover {
  color: #086AD8;
}

nav.navbar.bootsnav ul.nav > li.dropdown > a.dropdown-toggle:hover::after {
  content: "\f106";
}

nav.navbar.bootsnav ul.nav > li.dropdown > a.dropdown-toggle::after {
  content: "\f107";
  font-family: "Font Awesome 5 Pro";
  margin-left: 5px;
  margin-top: 2px;
  border: none;
  font-weight: 900;
  transition: all 0.35s ease-in-out;
}

.navbar .side .widget.social li a {
  color: #ffffff;
  display: inline-block;
  font-weight: 600;
  height: 40px;
  line-height: 42px;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0;
  text-align: center;
  width: 40px;
  border-radius: 5px;
  font-size: 13px;
}

.navbar .side .widget.social li a:hover {
  background: #086AD8 none repeat scroll 0 0;
  color: #ffffff;
}

.navbar.color-yellow .side .widget.social li a:hover {
  background: #ff9800 none repeat scroll 0 0;
}

.navbar .side .widget li {
  display: block;
  font-family: 'Inter', sans-serif;
  font-size: 15px;
  margin-bottom: 15px;
}

.navbar .side .widget.opening-hours li {
  border-bottom: 1px solid #e7e7e7;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
  padding-bottom: 10px;
  text-transform: uppercase;
}

.navbar .side .widget.opening-hours li:last-child {
  border: medium none;
  margin: 0;
  padding: 0;
}

.navbar .side .widget li span {
  float: right;
}

.navbar .side.barber .widget li i {
  background: #bc9355 none repeat scroll 0 0;
}

.bootsnav .side .widget.search input {
  border: 1px solid #e7e7e7;
  box-shadow: inherit;
  width: 100%;
}

.navbar .side .widget li:last-child {
  margin: 0;
}

.navbar .side .widget h4 {
  display: block;
  font-weight: 800;
  margin-bottom: 25px;
  padding-bottom: 15px;
  position: relative;
  text-transform: capitalize;
  z-index: 1;
  border-bottom: 1px dashed #dddddd;
}

.navbar .widget .address {
  padding-top: 5px;
}

.navbar .side .address li {
  display: block;
}

.navbar .side .address li .icon, 
.navbar .side .address li .info {
  display: table-cell;
  vertical-align: middle;
}

.navbar .side .address li .icon i {
  background: #f4f4f4 none repeat scroll 0 0;
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  color: #086AD8;
  font-size: 20px;
  height: 50px;
  line-height: 48px;
  text-align: center;
  width: 50px;
}

.navbar .side .address li .info span {
  color: #0e2b3d;
  display: block;
  float: none;
  font-weight: 600;
  text-transform: uppercase;
}

.navbar .side .address li .info {
  padding-left: 15px;
}

.navbar .side .widget .profile-thumb img {
  border: 2px solid #e7e7e7;
  height: 150px;
  margin-top: 10px;
  padding: 3px;
  width: 150px;
}

.side .widget {
  position: relative;
  z-index: 1;
  margin-bottom: 50px;
}

.side .widget:last-child {
  margin-bottom: 0;
}

.side img {
  margin-bottom: 20px;
}

.side .widget .title {
  margin-bottom: 15px;
}

.side .widget ul.link {
  padding: 0;
  margin: 0;
  list-style: none;
}

.side .widget ul.link li a {
  color: #086AD8;
  display: block;
  font-weight: bold;
  letter-spacing: 1px;
  padding: 5px 0;
  text-transform: uppercase;
}

.color-yellow .side .widget ul.link li a {
  color: #ff9800;
}

.side .widget ul.link li a:focus, 
.side .widget ul.link li a:hover {
  color: #fff;
  text-decoration: none;
}

nav.navbar.bootsnav .share {
  padding: 0 30px;
  margin-bottom: 30px;
}

nav.navbar.bootsnav .share ul {
  display: inline-block;
  padding: 0;
  margin: 0 0 -7px 0;
  list-style: none;
}

nav.navbar.bootsnav .share ul > li {
  float: left;
  display: block;
  margin-right: 5px;
}

nav.navbar.bootsnav .share ul > li > a {
  border-radius: 3px;
  display: inline-block;
  margin-right: 20px;
  text-align: center;
  vertical-align: middle;
}

nav.navbar.bootsnav .share.dark ul > li > a {
  background-color: #086AD8;
  color: #ffffff;
}

nav.navbar.bootsnav.color-yellow .share.dark ul > li > a {
  background-color: #ff9800;
}

nav.navbar.bootsnav .share ul > li > a:hover {
  color: #086AD8;
}

nav.navbar.bootsnav.color-yellow .share ul > li > a:hover {
  color: #ff9800;
}

nav.navbar.bootsnav.navbar-fixed {
  display: block;
  position: fixed;
  width: 100%;
  z-index: 100;
  box-shadow: 0 8px 25px 0 rgba(0, 0, 0, 0.04);
  -moz-box-shadow: 0 8px 25px 0 rgba(0, 0, 0, 0.04);
  -webkit-box-shadow: 0 8px 25px 0 rgba(0, 0, 0, 0.04);
  -o-box-shadow: 0 8px 25px 0 rgba(0, 0, 0, 0.04);
  top: 0;
}

nav.navbar.bootsnav.navbar-fixed.nav-box.no-background {
  top: 50px;
  width: auto;
  left: auto;
}

nav.navbar.bootsnav.navbar-fixed.nav-box.no-background.inc-top-bar {
  top: 90px;
}

nav.navbar.bootsnav.navbar-fixed.nav-box.no-background .navbar-collapse {
  background: #ffffff;
}

nav.navbar.bootsnav.navbar-fixed.nav-box.no-background ul.nav > li > a, 
nav.navbar.bootsnav.navbar-fixed.nav-box.no-background .attr-nav > ul > li > a {
  color: #333333;
}

nav.navbar.bootsnav.navbar-fixed.nav-box {
  left: 0;
  top: 0;
  width: 100%;
}

nav.navbar.bootsnav.navbar-fixed.no-background {
  display: block;
  position: absolute;
  width: 100%;
  z-index: 100;
  box-shadow: 0 0 0;
  -moz-box-shadow: 0 0 0;
  -webkit-box-shadow: 0 0 0;
  -o-box-shadow: 0 0 0;
}

nav.navbar.bootsnav {
  z-index: 100;
}

.wrap-sticky {
  position: relative;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.wrap-sticky nav.navbar.bootsnav {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  margin: 0;
}

.wrap-sticky nav.navbar.bootsnav.sticked {
  position: fixed;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  top: 0;
}

@media (min-width: 1024px) and (max-width: 1400px) {
  body.wrap-nav-sidebar .wrapper .container {
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media (min-width: 1024px) and (max-width: 1200px) {
  nav.navbar.bootsnav ul.nav > li > a {
    padding: 35px 12px !important;
  }

  nav.navbar.bootsnav.sticked ul.nav > li > a {
    padding: 30px 12px !important;
  }
}

@media (min-width: 1024px) {


  .navbar .container > .nav-box {
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  }

  .navbar.dark .container > .nav-box {
    border-bottom: 1px solid #e7e7e7;
  }

  .navbar.no-background .attr-nav i {
    color: #ffffff;
  }

  .navbar.no-background.dark .attr-nav i {
    color: #232323;
  }

  .navbar.no-background .attr-nav p {
    color: #ffffff;
  }

  .navbar.no-background .attr-nav p strong {
    color: #ffffff;
  }

  nav.navbar.bootsnav.bg-theme {
    background: #086AD8;
  }

  .attr-nav.extra-color > ul > li {
    margin-left: 15px;
    padding: 20px 0;
  }

  .navbar.no-background .attr-nav.extra-color > ul > li > a {
    color: #ffffff;
  }

  .attr-nav.extra-color > ul > li.side-menu > a {
    display: inline-block;
    padding: 0 !important;
    background: #ffffff;
    color: #086AD8 !important;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    text-align: center;
    line-height: 50px;
    box-shadow: 0 0 10px #cccccc;
  }

  nav.navbar.bootsnav.navbar-fixed.nav-box .attr-nav {
    padding-right: 30px;
  }

  nav.navbar.bootsnav.navbar-fixed.nav-box.no-background .navbar-collapse {
    padding-left: 15px;
  }


/* Custom */
  nav.navbar.navbar-default.bootsnav.navbar-fixed-light.no-background {
    background: #ffffff;
    box-shadow: 0 8px 25px 0 rgba(0, 0, 0, 0.04);
  }

  nav.navbar.navbar-default.bootsnav.navbar-fixed-light::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    height: 100%;
    width: 15%;
    background: #086AD8;
    z-index: -1;
  }

  nav.navbar.navbar-default.bootsnav.navbar-fixed-light .logo-responsive {
    display: none;
  }

  nav.navbar.navbar-default.bootsnav.navbar-fixed-light a.navbar-brand {
    position: relative;
    z-index: 1;
    padding-left: 0;
    padding-right: 30px;
  }

  nav.navbar.navbar-default.bootsnav.navbar-fixed-light a.navbar-brand::before {
    position: absolute;
    right: 0;
    top: 0;
    content: "";
    height: 93px;
    width: 500%;
    background: #086AD8;
    z-index: -1;
  }

  nav.navbar.bootsnav ul.nav .dropdown-menu .dropdown-menu {
    margin-top: -2px;
  }

  nav.navbar.bootsnav ul.nav.navbar-right .dropdown-left .dropdown-menu .dropdown-menu {
    left: -200px;
  }

  nav.navbar.bootsnav ul.nav.navbar-right .dropdown-menu {
    right: auto;
  }

  nav.navbar.bootsnav ul.nav.navbar-right .dropdown-left .dropdown-menu {
    right: 0;
    left: auto;
  }

  nav.navbar.bootsnav ul.nav > li > a {
    font-size: 13px;
    letter-spacing: 0.06em;
    padding: 40px 15px;
    text-transform: uppercase;
  }

  nav.navbar.bootsnav ul.nav > li > a.active {
    color: #086AD8;
  }

  .eastern-blue nav.navbar.bootsnav ul.nav > li > a.active {
    color: #1baaa0;
  }

  .orange nav.navbar.bootsnav ul.nav > li > a.active {
    color: #FF5621;
  }

  nav.navbar.bootsnav.active-bg ul.nav > li > a.active {
    background: #086AD8 !important;
  }

  nav.navbar.bootsnav.active-border ul.nav > li > a.active {
    position: relative;
    z-index: 1;
  }
  
  nav.navbar.bootsnav.small-pad ul.nav > li > a {
    padding: 35px 15px !important;
  }

  nav.navbar.bootsnav.small-pad.logo-less ul.nav > li > a {
    padding: 30px 15px !important;
  }

  nav.navbar.bootsnav.bg-theme ul.nav > li > a {
    color: #ffffff;
  }

  nav.navbar.bootsnav.bg-theme ul.nav > li > a:hover {
    color: #ffffff;
  }

  nav.bootsnav.small-pad .attr-nav > ul > li {
    padding: 25px 0;
  }

  nav.bootsnav.bg-theme .attr-nav > ul > li,
  nav.bootsnav.bg-theme .attr-nav > ul > li a {
    color: #ffffff;
  }

  nav.bootsnav.small-pad.logo-less .attr-nav > ul > li {
    padding: 17px 0;
  }

  .navbar.bootsnav.bg-theme .attr-nav li.quote-btn a {
    background: #ffffff;
    padding: 10px 35px !important;
    color: #086AD8;
    position: relative;
    top: 0;
  }

  .navbar.bootsnav.bg-theme .attr-nav li.quote-btn {
    padding: 13px 0;
    margin: 0;
  }

  nav.navbar.small-pad .attr-nav {
    min-height: inherit;
  }

  nav.navbar.bootsnav .navbar-brand {
    padding: 20px 15px;
  }

  .container-full a.navbar-brand {
    padding-left: 0 !important;
  }

  nav.navbar.bootsnav ul.nav > li.active > a {
    color: #086AD8;
  }

  nav.navbar.bootsnav.color-yellow ul.nav > li.active > a {
    color: #ff9800;
  }

  nav.navbar.bootsnav.barber ul.nav > li.active > a {
    color: #bc9355;
  }

  nav.navbar.bootsnav.active-full ul.nav > li > a.active,
    nav.navbar.bootsnav.active-full ul.nav > li > a:hover {
    background: #086AD8 none repeat scroll 0 0 !important;
    color: #ffffff;
  }

  nav.navbar.bootsnav.active-full.color-yellow ul.nav > li > a.active,
  nav.navbar.bootsnav.active-full.color-yellow ul.nav > li > a:hover {
    background: #ff9800 none repeat scroll 0 0 !important;
  }

  nav.navbar.bootsnav.active-full ul.nav > li.active > a:hover {
    color: #ffffff;
  }

  nav.navbar.bootsnav ul.nav > li > a:hover {
    color: #086AD8;
  }

  .eastern-blue nav.navbar.bootsnav ul.nav > li > a:hover {
    color: #1baaa0;
  }

  .orange nav.navbar.bootsnav ul.nav > li > a:hover {
    color: #FF5621;
  }

  .cornflower-blue nav.navbar.bootsnav ul.nav > li > a:hover {
    color: #634ded;
  }

  nav.navbar.bootsnav.color-yellow ul.nav > li > a:hover {
    color: #ff9800;
  }

  nav.navbar.bootsnav.barber ul.nav > li > a:hover {
    color: #bc9355;
  }

  nav.op-nav.bootsnav ul.nav.navbar-nav li a {
    text-transform: uppercase;
  }

  .social-links.sl-default a {
    border: 1px solid #ffffff;
    color: #ffffff;
    display: inline-block;
    float: left;
    height: 30px;
    line-height: 30px;
    margin-bottom: 5px;
    margin-right: 5px;
    text-align: center;
    width: 30px;
  }

  nav.navbar.bootsnav li.dropdown ul.dropdown-menu {
    background: #fff none repeat scroll 0 0;
    border: 1px solid transparent;
    border-radius: 2px;
    box-shadow: 0 5px 50px 0 rgba(0, 0, 0, 0.15);
    padding: 0;
    width: 250px;
  }

  nav.navbar.bootsnav li.dropdown ul.dropdown-menu.cart-list {
    left: auto;
    min-width: 300px;
    right: 0;
    border: none !important;
    margin-top: -5px;
    box-shadow: 0 5px 50px 0 rgba(0, 0, 0, 0.15);
  }

  nav.navbar.bootsnav.navbar-sticky.sticked li.dropdown ul.dropdown-menu.cart-list {
    margin-top: -8px;
  }

  nav.navbar.bootsnav li.dropdown ul.dropdown-menu > li a:hover, 
  nav.navbar.bootsnav li.dropdown ul.dropdown-menu > li a:hover {
    background-color: transparent;
  }

  nav.navbar.bootsnav li.dropdown ul.dropdown-menu > li > a {
    padding: 10px 15px;
    border-bottom: solid 1px #f5f5f5;
    color: #333333;
    font-weight: 600;
    text-transform: capitalize;
    display: block;
  }

  nav.navbar.bootsnav li.dropdown ul.dropdown-menu > li:last-child > a {
    border-bottom: none;
  }

  nav.navbar.bootsnav ul.navbar-right li.dropdown ul.dropdown-menu li a {
    color: #333333;
    display: block;
    font-size: 14px;
    margin-bottom: 0;
    padding: 10px 15px;
    text-align: left;
    text-transform: capitalize;
    width: 100%;
  }

  nav.navbar.bootsnav ul.navbar-right li.dropdown ul.dropdown-menu li a:hover {
    color: #086AD8;
  }

  nav.navbar.bootsnav.color-yellow ul.navbar-right li.dropdown ul.dropdown-menu li a:hover {
    color: #ff9800;
  }

  nav.navbar.bootsnav ul.navbar-left li.dropdown ul.dropdown-menu li a:hover {
    color: #086AD8;
  }

  nav.navbar.bootsnav.color-yellow ul.navbar-left li.dropdown ul.dropdown-menu li a:hover {
    color: #ff9800;
  }

  nav.navbar.bootsnav ul.navbar-right li.dropdown.dropdown-left ul.dropdown-menu li a {
    text-align: right;
  }

  nav.navbar.bootsnav li.dropdown ul.dropdown-menu li.dropdown > a.dropdown-toggle:before {
    font-family: 'Font Awesome 5 Pro';
    font-weight: 900;
    float: right;
    content: "\f105";
    margin-top: 0;
  }

  nav.navbar.bootsnav ul.navbar-right li.dropdown ul.dropdown-menu li.dropdown > a.dropdown-toggle:before {
    font-family: 'Font Awesome 5 Pro';
    font-weight: 900;
    float: right;
    content: "\f105";
    margin-top: 0;
  }

  nav.navbar.bootsnav ul.navbar-right li.dropdown.dropdown-left ul.dropdown-menu li.dropdown > a.dropdown-toggle:before {
    font-family: 'Font Awesome 5 Pro';
    font-weight: 900;
    float: left;
    content: "\f104";
    margin-top: 0;
  }

  nav.navbar.bootsnav li.dropdown ul.dropdown-menu ul.dropdown-menu {
    top: -1px;
  }

  nav.navbar.bootsnav ul.dropdown-menu.megamenu-content {
    padding: 0 15px !important;
  }

  nav.navbar.bootsnav ul.dropdown-menu.megamenu-content > li {
    padding: 25px 0 20px;
  }

  nav.navbar.bootsnav ul.dropdown-menu.megamenu-content.tabbed {
    padding: 0;
  }

  nav.navbar.bootsnav ul.dropdown-menu.megamenu-content.tabbed > li {
    padding: 0;
  }

  nav.navbar.bootsnav ul.dropdown-menu.megamenu-content .col-menu {
    padding: 0 30px;
    margin: 0 -0.5px;
    border-left: solid 1px #f0f0f0;
    border-right: solid 1px #f0f0f0;
  }

  nav.navbar.bootsnav ul.dropdown-menu.megamenu-content .col-menu:first-child {
    border-left: none;
  }

  nav.navbar.bootsnav ul.dropdown-menu.megamenu-content .col-menu:last-child {
    border-right: none;
  }

  nav.navbar.bootsnav ul.dropdown-menu.megamenu-content .content {
    display: none;
  }

  nav.navbar.bootsnav ul.dropdown-menu.megamenu-content .content ul.menu-col li a {
    border-bottom: medium none;
    color: #333333;
    display: block;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0;
    padding: 8px 0;
    text-align: left;
    text-transform: capitalize;
    width: 100%;
  }

  nav.navbar.bootsnav ul.dropdown-menu.megamenu-content .content ul.menu-col li a:hover {
    padding-left: 10px;
  }

  nav.navbar.bootsnav ul.dropdown-menu.megamenu-content .content ul.menu-col li a:hover {
    color: #086AD8;
  }

  nav.navbar.bootsnav.color-yellow ul.dropdown-menu.megamenu-content .content ul.menu-col li a:hover {
    color: #ff9800;
  }

  nav.navbar.bootsnav.on ul.dropdown-menu.megamenu-content .content {
    display: block !important;
    height: auto !important;
  }

  nav.navbar.bootsnav.no-background {
    background-color: transparent;
    border: none;
  }

  nav.navbar.navbar-default.inc-border.navbar-fixed.no-background.bootsnav {
    border-bottom: 1px solid #e7e7e7;
  }

  nav.navbar.bootsnav.navbar-transparent .attr-nav {
    padding-left: 15px;
    margin-left: 30px;
  }

  nav.navbar.bootsnav.navbar-transparent.white {
    background-color: rgba(255, 255, 255, 0.3);
    border-bottom: solid 1px #bbb;
  }

  nav.navbar.navbar-inverse.bootsnav.navbar-transparent.dark, 
  nav.navbar.bootsnav.navbar-transparent.dark {
    background-color: rgba(0, 0, 0, 0.3);
    border-bottom: solid 1px #555;
  }

  nav.navbar.bootsnav.navbar-transparent.white .attr-nav {
    border-left: solid 1px #bbb;
  }

  nav.navbar.navbar-inverse.bootsnav.navbar-transparent.dark .attr-nav, 
  nav.navbar.bootsnav.navbar-transparent.dark .attr-nav {
    border-left: solid 1px #555;
  }

  nav.navbar.bootsnav.no-background.white .attr-nav > ul > li > a, 
  nav.navbar.bootsnav.navbar-transparent.white .attr-nav > ul > li > a, 
  nav.navbar.bootsnav.navbar-transparent.white ul.nav > li > a, 
  nav.navbar.bootsnav.no-background.white ul.nav > li > a {
    color: #fff;
  }

  nav.navbar.bootsnav.navbar-transparent.dark .attr-nav > ul > li > a, 
  nav.navbar.bootsnav.navbar-transparent.dark ul.nav > li > a {
    color: #eee;
  }

  nav.navbar.bootsnav.navbar-fixed.navbar-transparent .logo-scrolled, 
  nav.navbar.bootsnav.navbar-fixed.no-background .logo-scrolled {
    display: none;
  }

  nav.navbar.bootsnav.navbar-fixed.navbar-transparent .logo-display, 
  nav.navbar.bootsnav.navbar-fixed.no-background .logo-display {
    display: block;
  }

  nav.navbar.bootsnav.navbar-fixed .logo-display {
    display: none;
  }

  nav.navbar.bootsnav.navbar-fixed .logo-scrolled {
    display: block;
  }

  .attr-nav > ul > li.dropdown ul.dropdown-menu {
    margin-top: 0;
    margin-left: 55px;
    width: 250px;
    left: -250px;
  }

  .eastern-blue .attr-nav.attr-bg {
    position: relative;
    z-index: 1;
    padding-left: 25px;
  }

  .eastern-blue .attr-nav.attr-bg::after {
    top: 0;
    left: 0;
    content: "";
    position: absolute;
    height: 100%;
    width: 400px;
    background: #1baaa0;
    z-index: -1;
  }

  .eastern-blue .attr-nav.attr-bg a {
    color: #ffffff;
  }

  nav.navbar.bootsnav.menu-center .container {
    position: relative;
  }

  nav.navbar .container-full {
    padding: 0;
  }

  nav.navbar.bootsnav.menu-center ul.nav.navbar-center {
    float: none;
    margin: 0 auto;
    display: table;
    table-layout: fixed;
  }

  nav.navbar.bootsnav.menu-center .attr-nav {
    right: 15px;
  }

  nav.bootsnav.navbar-brand-top .navbar-header {
    display: block;
    width: 100%;
    text-align: center;
  }

  nav.bootsnav.navbar-brand-top ul.nav > li.dropdown > ul.dropdown-menu {
    margin-top: 0px;
  }

  nav.bootsnav.navbar-brand-top ul.nav > li.dropdown.megamenu-fw > ul.dropdown-menu {
    margin-top: 0;
  }

  nav.bootsnav.navbar-brand-top .navbar-header .navbar-brand {
    display: inline-block;
    float: none;
    margin: 0;
  }

  nav.bootsnav.navbar-brand-top .navbar-collapse {
    text-align: center;
  }

  nav.bootsnav.navbar-brand-top ul.nav {
    display: inline-block;
    float: none;
    margin: 0 0 -5px 0;
  }

  nav.bootsnav.brand-center .navbar-header {
    display: block;
    width: 100%;
    position: absolute;
    text-align: center;
    top: 0;
    left: 0;
  }

  nav.bootsnav.brand-center .navbar-brand {
    display: inline-block;
    float: none;
  }

  nav.bootsnav.brand-center.center-side .navbar-brand {
    display: inline-block;
    float: none;
    padding: 15px 0;
  }

  nav.bootsnav.brand-center .navbar-collapse {
    text-align: center;
    display: inline-block;
    padding-left: 0;
    padding-right: 0;
  }

  nav.bootsnav.brand-center ul.nav > li.dropdown > ul.dropdown-menu {
    margin-top: 0px;
  }

  nav.bootsnav.brand-center ul.nav > li.dropdown.megamenu-fw > ul.dropdown-menu {
    margin-top: 0;
  }

  nav.bootsnav.brand-center .navbar-collapse .col-half {
    width: 50%;
    float: left;
    display: block;
  }

  nav.bootsnav.brand-center .navbar-collapse .col-half.left {
    text-align: right;
    padding-right: 100px;
  }

  nav.bootsnav.brand-center .navbar-collapse .col-half.right {
    text-align: left;
    padding-left: 100px;
  }

  nav.bootsnav.brand-center ul.nav {
    float: none !important;
    margin-bottom: -5px !important;
    display: inline-block !important;
  }

  nav.bootsnav.brand-center ul.nav.navbar-right {
    margin: 0;
  }

  nav.bootsnav.brand-center.center-side .navbar-collapse .col-half.left {
    text-align: left;
    padding-right: 100px;
  }

  nav.bootsnav.brand-center.center-side .navbar-collapse .col-half.right {
    text-align: right;
    padding-left: 100px;
  }

  body.wrap-nav-sidebar .wrapper {
    padding-left: 260px;
    overflow-x: hidden;
  }

  nav.bootsnav.navbar-sidebar {
    position: fixed;
    width: 260px;
    overflow: hidden;
    left: 0;
    padding: 0 0 0 0 !important;
    background: #fff;
    -moz-box-shadow: 1px 0px 1px 0px #eee;
    -webkit-box-shadow: 1px 0px 1px 0px #eee;
    -o-box-shadow: 1px 0px 1px 0px #eee;
    box-shadow: 1px 0px 1px 0px #eee;
  }

  nav.bootsnav.navbar-sidebar.bg-dark {
    background: #fff none repeat scroll 0 0;
    -moz-box-shadow: 1px 0 6px 0 #040914;
    -webkit-box-shadow: 1px 0 6px 0 #040914;
    -o-box-shadow: 1px 0 6px 0 #040914;
    box-shadow: 1px 0 6px 0 #040914;
    left: 0;
    overflow: hidden;
    padding: 0 !important;
    position: fixed;
    width: 260px;
  }

  nav.bootsnav.navbar-sidebar.bg-dark {
    background-color: #1c1c1c;
  }

  nav.bootsnav.navbar-sidebar .scroller {
    width: 280px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  nav.bootsnav.navbar-sidebar .container-fluid, 
  nav.bootsnav.navbar-sidebar .container {
    padding: 0 !important;
  }

  nav.bootsnav.navbar-sidebar .navbar-header {
    float: none;
    display: block;
    width: 260px;
    padding: 10px 15px;
    margin: 10px 0 0 0 !important;
  }

  nav.bootsnav.navbar-sidebar .navbar-collapse {
    padding: 0 !important;
    width: 260px;
  }

  nav.bootsnav.navbar-sidebar ul.nav {
    float: none;
    display: block;
    width: 100%;
    padding: 0 15px !important;
    margin: 0 0 30px 0;
  }

  nav.bootsnav.navbar-sidebar ul.nav li {
    float: none !important;
  }

  nav.bootsnav.navbar-sidebar ul.nav > li {
    border-bottom: 1px solid #eeeeee;
  }

  nav.bootsnav.navbar-sidebar.bg-dark ul.nav > li {
    border-bottom: 1px solid #0f1a20;
  }

  nav.bootsnav.navbar-sidebar ul.nav > li > a {
    padding: 10px 15px;
  }

  nav.bootsnav.navbar-sidebar.bg-dark ul.nav > li > a {
    color: #ffffff;
  }

  nav.bootsnav.navbar-sidebar ul.nav > li > a:hover {
    color: #086AD8;
  }

  nav.bootsnav.navbar-sidebar.color-yellow ul.nav > li > a:hover {
    color: #ff9800;
  }

  nav.bootsnav.navbar-sidebar ul.nav > li.dropdown > a:after {
    float: right;
  }

  nav.bootsnav.navbar-sidebar ul.nav li.dropdown ul.dropdown-menu {
    left: 100%;
    top: 0;
    position: relative !important;
    left: 0 !important;
    width: 100% !important;
    height: auto !important;
    background-color: transparent;
    border: none !important;
    padding: 0;
    -moz-box-shadow: 0px 0px 0px;
    -webkit-box-shadow: 0px 0px 0px;
    -o-box-shadow: 0px 0px 0px;
    box-shadow: 0px 0px 0px;
  }

  nav.bootsnav.navbar-sidebar ul.nav .megamenu-content .col-menu {
    border: none !important;
  }

  nav.bootsnav.navbar-sidebar ul.nav > li.dropdown > ul.dropdown-menu {
    margin-bottom: 15px;
  }

  nav.bootsnav.navbar-sidebar ul.nav li.dropdown ul.dropdown-menu {
    padding-left: 15px;
    float: none;
    margin-bottom: 0;
  }

  nav.bootsnav.navbar-sidebar ul.nav li.dropdown ul.dropdown-menu li a {
    padding: 10px 15px;
    color: #333333;
    border: none;
  }

  nav.bootsnav.navbar-sidebar ul.nav li.dropdown ul.dropdown-menu ul.dropdown-menu {
    padding-left: 15px;
    margin-top: 0;
  }

  nav.bootsnav.navbar-sidebar ul.nav li.dropdown ul.dropdown-menu li.dropdown > a:before {
    font-family: 'FontAwesome';
    content: "\f105";
    float: right;
  }

  nav.bootsnav.navbar-sidebar ul.nav li.dropdown.on ul.dropdown-menu li.dropdown.on > a:before {
    content: "\f107";
  }

  nav.bootsnav.navbar-sidebar ul.dropdown-menu.megamenu-content > li {
    padding: 0 !important;
  }

  nav.bootsnav.navbar-sidebar .dropdown .megamenu-content .col-menu {
    display: block;
    float: none !important;
    padding: 0;
    margin: 0;
    width: 100%;
  }

  nav.bootsnav.navbar-sidebar .dropdown .megamenu-content .col-menu .title {
    padding: 7px 0;
    text-transform: none;
    font-weight: 400;
    letter-spacing: 0px;
    margin-bottom: 0;
    cursor: pointer;
    color: #333333;
    text-transform: uppercase;
  }

  nav.bootsnav.navbar-sidebar .dropdown .megamenu-content .col-menu .title:before {
    font-family: 'FontAwesome';
    content: "\f105";
    float: right;
  }

  nav.bootsnav.navbar-sidebar .dropdown .megamenu-content .col-menu.on .title:before {
    content: "\f107";
  }

  nav.bootsnav.navbar-sidebar .dropdown .megamenu-content .col-menu {
    border: none;
  }

  nav.bootsnav.navbar-sidebar .dropdown .megamenu-content .col-menu .content {
    padding: 0 0 0 15px;
  }

  nav.bootsnav.navbar-sidebar .dropdown .megamenu-content .col-menu ul.menu-col li a {
    padding: 3px 0 !important;
  }
}

@media (max-width: 1023px) {

  .attr-nav {
    margin-right: 0;
  }

  .attr-nav > ul > li.button {
    padding: 8px 0 !important;
  }

  .attr-nav > ul > li.button a {
    padding: 12px 40px !important;
  }

  .top-bar-area .item-flex.border-less > div.social {
    display: none;
  }

  nav.bootsnav.navbar-default.small-pad .top-search {
    top: 70px;
  }

  nav.navbar.bootsnav .navbar-brand {
    display: inline-block;
    float: none !important;
    margin: 0 !important;
  }

  nav.navbar.bootsnav.bg-theme .logo.desktop {
    display: none;
  }

  nav.navbar.bootsnav.bg-theme .logo.responsive {
    display: inline-block;
  }

  nav.navbar.bootsnav .navbar-header {
    float: none;
    display: block;
    text-align: center;
    padding-left: 30px;
    padding-right: 30px;
  }

  nav.navbar.bootsnav .navbar-toggle {
    display: inline-block;
    float: left;
    margin-right: -200px;
    margin-top: 20px;
  }

  nav.navbar.bootsnav.navbar-fixed.no-background {
    box-shadow: 0 8px 25px 0 rgba(0, 0, 0, 0.04);
  }

  nav.navbar.bootsnav.navbar-fixed .navbar-toggle {
    margin-top: 20px;
  }

  nav.navbar.bootsnav.navbar-fixed img {
    position: relative;
    top: 0;
  }

  nav.navbar.nav-box.bootsnav.navbar-fixed img {
    top: 0;
  }

  nav.navbar.bootsnav.small-pad {
    min-height: 60px;
  }

  nav.navbar.bootsnav.attr-border .navbar-header {
    min-height: 60px;
  }

  nav.navbar.bootsnav.logo-less .navbar-brand {
    display: inline-block;
  }

  nav.navbar.bootsnav.navbar-default.navbar-fixed.navbar-transparent.inc-topbar {
    margin-top: 47px;
  }

  .navbar-brand > img {
    height: 50px !important;
  }

  nav.navbar.bootsnav.navbar-transparent.pad-top {
    background: #ffffff none repeat scroll 0 0 !important;
    border-bottom: none !important;
    margin-top: 0 !important;
  }

  nav.navbar.bootsnav.attr-border .navbar-header {
    border-bottom: 1px solid #e7e7e7;
  }

  nav.navbar.attr-border .attr-nav,
  nav.navbar.bootsnav.sticked.attr-border .attr-nav {
    height: 60px;
    min-height: 60px;
  }

  nav.navbar .quote-btn {
    margin-top: 0;
  }

  .top-search {
    top: 80px;
  }

  nav.bootsnav.navbar-default.small-pad.logo-less .top-search {
    top: 80px;
  }

  .navbar.navbar-fixed .top-search {
    top: 78px;
  }

  nav.navbar.bootsnav ul.nav > li.dropdown > a.dropdown-toggle::after {
    display: none;
  }

  .attr-nav > ul > li {
    padding: 13px 0 !important;
  }

  .attr-nav > ul > li.search {
    padding: 8px 0 !important;
    margin-left: 10px;
  }

  .attr-nav > ul > li.side-menu {
    padding: 8px 0 !important;
    margin-left: 15px;
  }

  nav.navbar.attr-border .attr-nav::before {
    margin-top: -12px;
  }

  .language-switcher .dropdown-toggle {
    padding: 0 !important;
    margin-right: 10px;
  }

  .language-switcher .dropdown-menu {
    margin-top: 10px;
  }

  .navbar.navbar-fixed.transparent .attr-nav > ul > li {
    padding: 20px 0 !important;
  }

  nav.navbar-transparent .attr-nav > ul > li.contact {
    color: #0e2b3d !important;
  }

  nav.navbar.navbar-default.bootsnav.navbar-fixed-light .logo.default {
    display: none;
  }

  nav.navbar-transparent .attr-nav > ul > li.contact i {
    color: #0e2b3d !important;
  }

  header nav.navbar.border.bootsnav.navbar-fixed.no-background ul li a {
    margin-left: 0;
  }

  nav.bootsnav.navbar-default.info-topbar .navbar-header {
    display: block !important;
  }

  nav.bootsnav.navbar-default.info-topbar ul li a.active::after {
    display: none;
  }

  nav.bootsnav.navbar-default.info-topbar ul li a::after {
    display: none;
  }

  .attr-nav > ul > li > a {
    padding: 17px 0 !important;
  }

  .top-bar-area .logo {
    display: none;
  }

  .top-bar-area.shadow::after {
    display: none;
  }

  .top-bar-area .info {
    text-align: center;
  }

  nav.navbar.shadow.navbar-inverse.bootsnav.navbar-transparent.dark, 
  nav.navbar.shadow.bootsnav.navbar-transparent.dark {
    background-color: #ffffff !important;
    border-bottom: medium none navy;
  }

  .navbar-default.bootsnav.navbar-sidebar.bg-dark .social-share {
    padding: 20px 0;
  }

  .attr-nav > ul li.dropdown ul.dropdown-menu {
    margin-top: -8px !important;
  }

  nav.navbar.bootsnav.no-background.wt-bar {
    background-color: #ffffff;
    margin: 0;
    position: relative;
    top: 0 !important;
  }

  .topbar-area.com.bg-transparent {
    border-bottom: 1px solid #e7e7e7 !important;
    position: relative !important;
  }

  .topbar-area.com.bg-transparent.text-light a {
    color: #333333 !important;
  }

  nav.bg-dark.navbar.bootsnav .navbar-toggle {
    color: #ffffff;
  }

  nav.bg-dark.navbar.bootsnav ul.nav > li > a {
    background-color: transparent;
    color: #333333 !important;
  }

  nav.navbar.bootsnav ul.nav li.dropdown > ul.dropdown-menu {
    box-shadow: inherit !important;
  }

  nav.navbar.bootsnav ul.nav li.dropdown > ul.dropdown-menu li > a {
    border-bottom: 1px solid #e7e7e7 !important;
    font-weight: 600;
  }

  nav.bootsnav.nav-box .dropdown-menu {
    left: 0;
  }

  nav.navbar.bootsnav ul.nav li.dropdown > ul.dropdown-menu li:hover > a {
    background-color: transparent !important;
    color: #086AD8 !important;
  }

  nav.navbar.bootsnav.color-yellow ul.nav li.dropdown > ul.dropdown-menu li:hover > a {
    color: #ff9800 !important;
  }

  nav.bg-dark.navbar.bootsnav ul.nav li.dropdown > ul.dropdown-menu li a {
    background: transparent none repeat scroll 0 0 !important;
    border-bottom: 1px solid #e7e7e7;
    color: #333333 !important;
  }

  nav.bg-dark.navbar.bootsnav ul.nav li.dropdown > ul.dropdown-menu li a:hover {
    color: #5cb85c !important;
  }

  nav.navbar.bootsnav .navbar-collapse.collapse.in,
  nav.navbar.bootsnav .navbar-collapse.collapse.show {
    background: #ffffff none repeat scroll 0 0;
    display: block;
    margin-bottom: 30px;
  }

  nav.bg-dark.navbar.bootsnav .dropdown .megamenu-content .col-menu .title {
    border-bottom: 1px solid #e0e0e0;
    font-size: 14px;
  }

  nav.navbar.bootsnav .navbar-collapse {
    border: none;
    margin-bottom: 0;
  }

  nav.navbar.bootsnav.no-full .navbar-collapse {
    max-height: 350px;
    overflow-y: auto !important;
  }

  nav.navbar.bootsnav .navbar-collapse.collapse {
    display: none !important;
  }

  nav.navbar.bootsnav .navbar-collapse.collapse.in,
  nav.navbar.bootsnav .navbar-collapse.collapse.show {
    display: block !important;
  }

  nav.navbar.bootsnav .navbar-nav {
    float: none !important;
    padding-left: 30px;
    padding-right: 30px;
    margin: 0px;
  }

  nav.navbar.bootsnav .navbar-nav > li {
    float: none;
  }

  nav.navbar.bootsnav li.dropdown a.dropdown-toggle:before {
    font-family: 'Font Awesome 5 Pro';
    font-weight: 900;
    content: "\f105";
    float: right;
    font-size: 16px;
    margin-left: 10px;
  }

  nav.navbar.bootsnav li.dropdown.on > a.dropdown-toggle:before {
    content: "\f107";
  }

  nav.navbar.bootsnav .navbar-nav > li > a {
    display: block;
    width: 100%;
    border-bottom: solid 1px #e0e0e0;
    padding: 13px 0 !important;
    border-top: solid 1px #e0e0e0;
    margin-bottom: -1px;
  }

  nav.navbar.bootsnav .navbar-nav > li:first-child > a {
    border-top: none;
  }

  nav.navbar.bootsnav ul.navbar-nav.navbar-left > li:last-child > ul.dropdown-menu {
    border-bottom: solid 1px #e0e0e0;
  }

  nav.navbar.bootsnav ul.nav li.dropdown li a.dropdown-toggle {
    float: none !important;
    position: relative;
    display: block;
    width: 100%;
  }

  nav.navbar.bootsnav ul.nav li.dropdown ul.dropdown-menu {
    width: 100%;
    position: relative !important;
    background-color: transparent;
    float: none;
    border: none;
    padding: 0 0 0 15px !important;
    margin: 0 0 -1px 0 !important;
    -moz-box-shadow: 0px 0px 0px;
    -webkit-box-shadow: 0px 0px 0px;
    -o-box-shadow: 0px 0px 0px;
    box-shadow: 0px 0px 0px;
    -moz-border-radius: 0px 0px 0px;
    -webkit-border-radius: 0px 0px 0px;
    -o-border-radius: 0px 0px 0px;
    border-radius: 0px 0px 0px;
  }

  nav.navbar.bootsnav ul.nav li.dropdown ul.dropdown-menu > li > a {
    border-bottom: 1px solid #e0e0e0;
    color: #333333;
    display: block;
    font-size: 14px;
    font-weight: 600;
    padding: 10px 0;
    text-transform: capitalize;
    width: 100%;
  }

  nav.navbar.bootsnav ul.nav ul.dropdown-menu li a:hover, 
  nav.navbar.bootsnav ul.nav ul.dropdown-menu li a:focus {
    background-color: transparent;
  }

  nav.navbar.bootsnav ul.nav ul.dropdown-menu ul.dropdown-menu {
    float: none !important;
    left: 0;
    padding: 0 0 0 15px;
    position: relative;
    background: transparent;
    width: 100%;
  }

  nav.navbar.bootsnav ul.nav ul.dropdown-menu li.dropdown.on > ul.dropdown-menu {
    display: inline-block;
    margin-top: -10px;
  }

  nav.navbar.bootsnav li.dropdown ul.dropdown-menu li.dropdown > a.dropdown-toggle:after {
    display: none;
  }

  nav.navbar.bootsnav .dropdown .megamenu-content .col-menu .title {
    padding: 13px 15px 13px 0;
    line-height: 24px;
    text-transform: none;
    font-weight: 400;
    letter-spacing: 0px;
    margin-bottom: 0;
    cursor: pointer;
    border-bottom: solid 1px #e0e0e0;
    color: #333333;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
  }

  nav.navbar.bootsnav .dropdown .megamenu-content .col-menu ul > li > a {
    display: block;
    width: 100%;
    border-bottom: solid 1px #e0e0e0;
    padding: 10px 0;
  }

  nav.navbar.bootsnav .dropdown .megamenu-content .col-menu .title::before {
    content: "\f105";
    float: right;
    font-family: "Font Awesome 5 Pro";
    font-size: 16px;
    font-weight: 900;
    margin-left: 10px;
    position: relative;
    right: -15px;
  }

  nav.navbar.bootsnav .dropdown .megamenu-content .col-menu:last-child .title {
    border-bottom: none;
  }

  nav.navbar.bootsnav .dropdown .megamenu-content .col-menu.on:last-child .title {
    border-bottom: solid 1px #e0e0e0;
  }

  nav.navbar.bootsnav .dropdown .megamenu-content .col-menu:last-child ul.menu-col li:last-child a {
    border-bottom: none;
  }

  nav.navbar.bootsnav .dropdown .megamenu-content .col-menu.on .title:before {
    content: "\f107";
  }

  nav.navbar.bootsnav .dropdown .megamenu-content .col-menu .content {
    padding: 0 0 0 15px;
  }

  nav.bootsnav.brand-center .navbar-collapse {
    display: block;
  }

  nav.bootsnav.brand-center ul.nav {
    margin-bottom: 0px !important;
  }

  nav.bootsnav.brand-center .navbar-collapse .col-half {
    width: 100%;
    float: none;
    display: block;
  }

  nav.bootsnav.brand-center .navbar-collapse .col-half.left {
    margin-bottom: 0;
  }

  nav.bootsnav .megamenu-content {
    padding: 0;
  }

  nav.bootsnav .megamenu-content .col-menu {
    padding-bottom: 0;
  }

  nav.bootsnav .megamenu-content .title {
    cursor: pointer;
    display: block;
    padding: 10px 15px;
    margin-bottom: 0;
    font-weight: normal;
  }

  nav.bootsnav .megamenu-content .content {
    display: none;
  }

  .attr-nav {
    position: absolute;
    right: 60px;
  }

  .attr-nav > ul {
    padding: 0;
    margin: 0 -15px -7px 0;
  }

  .attr-nav > ul > li > a {
    padding: 16px 15px 15px;
  }

  .attr-nav > ul > li.dropdown > a.dropdown-toggle:before {
    display: none;
  }

  .attr-nav > ul > li.dropdown ul.dropdown-menu {
    margin-top: 2px;
    margin-left: 55px;
    width: 250px;
    left: -250px;
    border-top: none;
    box-shadow: 0 5px 50px 0 rgba(0, 0, 0, 0.15);
  }

  .top-search .container {
    padding: 0 45px;
  }

  nav.bootsnav.navbar-full ul.nav {
    margin-left: 0;
  }

  nav.bootsnav.navbar-full ul.nav > li > a {
    border: none;
  }

  nav.bootsnav.navbar-full .navbar-brand {
    float: left !important;
    padding-left: 0;
  }

  nav.bootsnav.navbar-full .navbar-toggle {
    display: inline-block;
    float: right;
    margin-right: 0;
    margin-top: 10px;
    top: 0;
  }

  nav.bootsnav.navbar-full .navbar-header {
    padding-left: 15px;
    padding-right: 15px;
  }

  nav.navbar.bootsnav.navbar-sidebar .share {
    padding: 30px 15px;
    margin-bottom: 0;
  }

  nav.navbar.bootsnav .megamenu-content.tabbed {
    padding-left: 0 !important;
  }

  nav.navbar.bootsnav .tabbed > li {
    padding: 25px 0;
    margin-left: -15px !important;
  }

  body > .wrapper {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  body.side-right > .wrapper {
    margin-left: 280px;
    margin-right: -280px !important;
  }

  nav.navbar.bootsnav.navbar-mobile .navbar-collapse {
    position: fixed;
    overflow-y: auto !important;
    overflow-x: hidden !important;
    display: block;
    background: #fff;
    z-index: 99;
    width: 280px;
    height: 100% !important;
    left: -280px;
    top: 0;
    padding: 0;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  nav.navbar.bootsnav.navbar-mobile .navbar-collapse.in,
  nav.navbar.bootsnav.navbar-mobile .navbar-collapse.show {
    left: 0;
  }

  nav.navbar.bootsnav.navbar-mobile ul.nav {
    width: 293px;
    padding-right: 0;
    padding-left: 15px;
  }

  nav.navbar.bootsnav.navbar-mobile ul.nav > li > a {
    padding: 15px 15px;
  }

  nav.navbar.bootsnav.navbar-mobile ul.nav ul.dropdown-menu > li > a {
    padding-right: 15px !important;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }

  nav.navbar.bootsnav.navbar-mobile ul.nav ul.dropdown-menu .col-menu .title {
    padding-right: 30px !important;
    padding-top: 13px !important;
    padding-bottom: 13px !important;
  }

  nav.navbar.bootsnav.navbar-mobile ul.nav ul.dropdown-menu .col-menu ul.menu-col li a {
    padding-top: 13px !important;
    padding-bottom: 13px !important;
  }

  nav.navbar.bootsnav.navbar-mobile .navbar-collapse [class*=' col-'] {
    width: 100%;
  }

  nav.navbar.bootsnav.navbar-fixed .logo-scrolled {
    display: inline-block !important;
  }

  nav.navbar.bootsnav.navbar-fixed .logo-display {
    display: none !important;
  }

  nav.navbar.bootsnav.navbar-mobile .tab-menu, 
  nav.navbar.bootsnav.navbar-mobile .tab-content {
    width: 100%;
    display: block;
  }
}

@media (max-width: 767px) {
  nav.navbar.bootsnav .navbar-header {
    padding-left: 15px;
    padding-right: 15px;
  }

  nav.navbar.bootsnav .navbar-nav {
    padding-left: 15px;
    padding-right: 15px;
    margin: 0;
  }

  .attr-nav {
    right: 30px;
  }

  .attr-nav > ul > li > a {
    padding: 16px 10px 15px;
    padding-left: 0 !important;
  }

  .attr-nav > ul > li.dropdown ul.dropdown-menu {
    left: -275px;
    box-shadow: 0 5px 50px 0 rgba(0, 0, 0, 0.15);
  }

  .top-search .container {
    padding: 0 15px;
  }

  nav.bootsnav.navbar-full .navbar-collapse {
    left: 15px;
  }

  nav.bootsnav.navbar-full .navbar-header {
    padding-right: 0;
  }

  nav.bootsnav.navbar-full .navbar-toggle {
    margin-right: -15px;
  }

  nav.bootsnav.navbar-full ul.nav > li > a {
    font-size: 18px !important;
    line-height: 14px !important;
    padding: 10px 10px !important;
  }

  nav.navbar.bootsnav.navbar-sidebar .share {
    padding: 30px 15px !important;
  }

  nav.navbar.bootsnav.navbar-sidebar .share {
    padding: 30px 0 !important;
    margin-bottom: 0;
  }

  nav.navbar.bootsnav.navbar-mobile.navbar-sidebar .share {
    padding: 30px 15px !important;
    margin-bottom: 0;
  }

  body.side-right > .wrapper {
    margin-left: 280px;
    margin-right: -280px !important;
  }

  nav.navbar.bootsnav.navbar-mobile .navbar-collapse {
    margin-left: 0;
  }

  nav.navbar.bootsnav.navbar-mobile ul.nav {
    margin-left: -15px;
  }

  nav.navbar.bootsnav.navbar-mobile ul.nav {
    border-top: solid 1px #fff;
  }

  li.close-full-menu {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
}

@media (min-width: 480px) and (max-width: 640px) {
  nav.bootsnav.navbar-full ul.nav {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.navbar-brand>img {
  display: initial;
  height: auto;
}

nav.bootsnav.navbar-default.info-topbar .attr-nav ul li a {
  font-size: 16px;
  margin-left: 25px;
  margin-right: 0;
}

nav.bootsnav.navbar-default.info-topbar.sticked .attr-nav ul li a {
  margin-right: 0;
}

nav.navbar.bootsnav.bg-dark .attr-nav > ul > li > a {
  color: #ffffff;
}

ul.cart-list > li.total > .btn {
  color: #0e2b3d;
  padding: 10px 25px !important;
}

@media (min-width: 1024px) {

  .attr-nav > ul > li {
    padding: 30px 0;
    font-size: 18px;
  }

  .attr-nav > ul.button > li {
    padding: 0;
    margin-top: 10px;
  }

  .attr-nav > ul > li.language-switcher {
    padding: 10px 0;
  }

  nav .attr-nav > ul > li.contact {
    padding: 31px 0;
    margin-left: 30px;
  }

  nav.navbar-transparent .attr-nav > ul > li.contact {
    color: #ffffff;
    margin-left: 0;
  }

  .attr-nav > ul > li.contact i {
    margin-right: 10px;
    font-size: 25px;
    position: relative;
    float: left;
    color: #086AD8;
    top: 3px;
  }

  .bootsnav.bg-theme .attr-nav > ul > li.contact i {
    color: #ffffff;
  }

  nav.navbar-transparent .attr-nav > ul > li.contact i {
    color: #ffffff;
  }

  .attr-nav > ul > li > a {
    font-size: 18px;
    padding: 8px 10px !important;
  }

  .attr-nav > ul.button > li > a {
    margin-left: 5px;
    padding: 20px 40px !important;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 800;
    background: #086AD8;
    color: #ffffff;
  }

  .attr-nav > ul.button > li > a i {
    font-weight: 500;
    margin-right: 3px;
  }

  .attr-nav > ul > li.button a {
    background: #086AD8;
    padding: 20px 40px !important;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 800;
    color: #ffffff;
  }

  .attr-nav > ul > li.button {
    padding: 10px 0;
    margin-left: 10px;
  }

  .attr-nav > ul > li.button a i {
    font-weight: 500;
    margin-right: 5px;
  }

  .attr-nav > ul > li.side-menu > a {
    padding-right: 0 !important;
  }

  .attr-nav > ul > li.quote-btn > a {
    padding: 8px 35px !important;
    margin-left: 5px;
    border-radius: 30px;
  }

  .attr-nav.menu li a {
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
  }

  .attr-nav.menu li {
    margin-left: 10px;
  }

  .attr-nav.menu li:last-child a {
    background: #086AD8 none repeat scroll 0 0;
    border-radius: 30px;
    color: #ffffff !important;
    padding: 5px 20px !important;
  }

  .color-yellow .attr-nav.menu li:last-child a {
    background: #ff9800 none repeat scroll 0 0;
  }

  nav.navbar ul.nav > li > a {
    padding: 30px 15px;
    font-weight: 300;
  }

  nav.navbar .navbar-brand {
    margin-top: 0;
  }

  nav.navbar .navbar-brand {
    margin-top: 0;
  }

  nav.navbar li.dropdown ul.dropdown-menu {
    border-top: solid 5px;
  }

  nav.navbar-center .navbar-brand {
    margin: 0 !important;
  }

  nav.navbar-brand-top .navbar-brand {
    margin: 10px !important;
  }

  nav.navbar-full .navbar-brand {
    position: relative;
  }

  nav.navbar-sidebar ul.nav, 
  nav.navbar-sidebar .navbar-brand {
    margin-bottom: 50px;
  }

  nav.navbar-sidebar ul.nav > li > a {
    padding: 10px 15px;
    font-weight: bold;
  }

  nav.navbar.bootsnav.navbar-transparent.white {
    background: transparent none repeat scroll 0 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

  nav.navbar.bootsnav.navbar-transparent.white.barber {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    background-color: rgba(35, 35, 35, 0.3);
  }

  nav.navbar.navbar-inverse.bootsnav.navbar-transparent.dark, 
  nav.navbar.bootsnav.navbar-transparent.dark {
    background-color: rgba(0, 0, 0, 0.3);
    border: none;
    box-shadow: 0 -1px 0 0 rgba(255, 255, 255, 0.1) inset;
  }

  nav.navbar.bootsnav.navbar-transparent.white .attr-nav {
    border-left: 1px solid rgba(255, 255, 255, 0.1);
    min-height: 90px;
  }

  nav.navbar.bootsnav.navbar-transparent.white .attr-nav.border-less {
    border: none;
  }

  nav.navbar.navbar-inverse.bootsnav.navbar-transparent.dark .attr-nav, 
  nav.navbar.bootsnav.navbar-transparent.dark .attr-nav {
    border-left: solid 1px rgba(255, 255, 255, 0.1);
    min-height: 90px;
  }

  nav.navbar.bootsnav.no-background.white .attr-nav > ul > li > a, 
  nav.navbar.bootsnav.navbar-transparent.white .attr-nav > ul > li > a, 
  nav.navbar.bootsnav.navbar-transparent.white ul.nav > li > a, 
  nav.navbar.bootsnav.no-background.white ul.nav > li > a {
    color: #fff;
  }

  nav.navbar.bootsnav.navbar-transparent.dark .attr-nav > ul > li > a, 
  nav.navbar.bootsnav.navbar-transparent.dark ul.nav > li > a {
    color: #eee;
  }
}

@media (max-width: 992px) {
  nav.navbar .navbar-brand {
    margin-top: 0;
    position: relative;
  }

  nav.navbar.navbar-sticky .navbar-brand {
    top: 0;
  }

  nav.navbar.navbar-sidebar .navbar-brand {
    top: 0;
  }

  nav.navbar .navbar-brand img.logo {
    height: 30px;
  }

  .attr-nav > ul > li > a {
    padding: 20px 15px 15px;
  }

  nav.navbar.navbar-mobile ul.nav > li > a {
    padding: 15px 15px;
  }

  nav.navbar.navbar-mobile ul.nav ul.dropdown-menu > li > a {
    padding-right: 15px !important;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }

  nav.navbar.navbar-mobile ul.nav ul.dropdown-menu .col-menu .title {
    padding-right: 30px !important;
    padding-top: 13px !important;
    padding-bottom: 13px !important;
  }

  nav.navbar.navbar-mobile ul.nav ul.dropdown-menu .col-menu ul.menu-col li a {
    padding-top: 13px !important;
    padding-bottom: 13px !important;
  }

  nav.navbar-full .navbar-brand {
    top: 0;
    padding-top: 10px;
  }
}

nav.navbar.navbar-inverse {
  background-color: #222;
  border-bottom: solid 1px #303030;
}

nav.navbar.navbar-inverse ul.cart-list > li.total > .btn {
  border-bottom: solid 1px #222 !important;
}

nav.navbar.navbar-inverse ul.cart-list > li.total .pull-right {
  color: #fff;
}

nav.navbar.navbar-inverse.megamenu ul.dropdown-menu.megamenu-content .content ul.menu-col li a, 
nav.navbar.navbar-inverse ul.nav > li > a {
  color: #eee;
}

nav.navbar.navbar-inverse ul.nav > li.dropdown > a {
  background-color: #222;
}

nav.navbar.navbar-inverse li.dropdown ul.dropdown-menu > li > a {
  color: #999;
}

nav.navbar.navbar-inverse ul.nav .dropdown-menu h1, 
nav.navbar.navbar-inverse ul.nav .dropdown-menu h2, 
nav.navbar.navbar-inverse ul.nav .dropdown-menu h3, 
nav.navbar.navbar-inverse ul.nav .dropdown-menu h4, 
nav.navbar.navbar-inverse ul.nav .dropdown-menu h5, 
nav.navbar.navbar-inverse ul.nav .dropdown-menu h6 {
  color: #fff;
}

nav.navbar.navbar-inverse .form-control {
  background-color: #333;
  border-color: #303030;
  color: #fff;
}

nav.navbar.navbar-inverse .attr-nav > ul > li > a {
  color: #eee;
}

nav.navbar.navbar-inverse .attr-nav > ul > li.dropdown ul.dropdown-menu {
  background-color: #222;
  border-left: solid 1px #303030;
  border-bottom: solid 1px #303030;
  border-right: solid 1px #303030;
}

nav.navbar.navbar-inverse ul.cart-list > li {
  border-bottom: solid 1px #303030;
  color: #eee;
}

nav.navbar.navbar-inverse ul.cart-list > li img {
  border: solid 1px #303030;
}

nav.navbar.navbar-inverse ul.cart-list > li.total {
  background-color: #333;
}

nav.navbar.navbar-inverse .share ul > li > a {
  background-color: #555;
}

nav.navbar.navbar-inverse .dropdown-tabs .tab-menu {
  border-right: solid 1px #303030;
}

nav.navbar.navbar-inverse .dropdown-tabs .tab-menu > ul > li > a {
  border-bottom: solid 1px #303030;
}

nav.navbar.navbar-inverse .dropdown-tabs .tab-content {
  border-left: solid 1px #303030;
}

nav.navbar.navbar-inverse .dropdown-tabs .tab-menu > ul > li > a:hover, 
nav.navbar.navbar-inverse .dropdown-tabs .tab-menu > ul > li > a:focus, 
nav.navbar.navbar-inverse .dropdown-tabs .tab-menu > ul > li.active > a {
  background-color: #333 !important;
}

nav.navbar-inverse.navbar-full ul.nav > li > a {
  border: none;
}

nav.navbar-inverse.navbar-full .navbar-collapse .wrap-full-menu {
  background-color: #222;
}

nav.navbar-inverse.navbar-full .navbar-toggle {
  background-color: #222 !important;
  color: #333333;
}

@media (min-width: 1024px) {
  nav.navbar.navbar-inverse ul.nav .dropdown-menu {
    background-color: #222 !important;
    border-left: solid 1px #303030 !important;
    border-bottom: solid 1px #303030 !important;
    border-right: solid 1px #303030 !important;
  }

  nav.navbar.navbar-inverse li.dropdown ul.dropdown-menu > li > a {
    border-bottom: solid 1px #303030;
  }

  nav.navbar.navbar-inverse ul.dropdown-menu.megamenu-content .col-menu {
    border-left: solid 1px #303030;
    border-right: solid 1px #303030;
  }

  nav.navbar.navbar-inverse.navbar-transparent.dark {
    background-color: rgba(0, 0, 0, 0.3);
    border-bottom: solid 1px #999;
  }

  nav.navbar.navbar-inverse.navbar-transparent.dark .attr-nav {
    border-left: solid 1px #999;
  }

  nav.navbar.navbar-inverse.no-background.white .attr-nav > ul > li > a, 
  nav.navbar.navbar-inverse.navbar-transparent.dark .attr-nav > ul > li > a, 
  nav.navbar.navbar-inverse.navbar-transparent.dark ul.nav > li > a, 
  nav.navbar.navbar-inverse.no-background.white ul.nav > li > a {
    color: #fff;
  }

  nav.navbar.navbar-inverse.no-background.dark .attr-nav > ul > li > a, 
  nav.navbar.navbar-inverse.no-background.dark .attr-nav > ul > li > a, 
  nav.navbar.navbar-inverse.no-background.dark ul.nav > li > a, 
  nav.navbar.navbar-inverse.no-background.dark ul.nav > li > a {
    color: #3f3f3f;
  }
}

@media (max-width: 992px) {
  nav.navbar.navbar-inverse .navbar-toggle {
    color: #eee;
    background-color: #222 !important;
  }

  nav.navbar.navbar-inverse .navbar-nav > li > a {
    border-top: solid 1px #303030;
    border-bottom: solid 1px #303030;
  }

  nav.navbar.navbar-inverse ul.nav li.dropdown ul.dropdown-menu > li > a {
    color: #999;
    border-bottom: solid 1px #303030;
  }

  nav.navbar.navbar-inverse .dropdown .megamenu-content .col-menu .title {
    border-bottom: solid 1px #303030;
    color: #eee;
  }

  nav.navbar.navbar-inverse .dropdown .megamenu-content .col-menu ul > li > a {
    border-bottom: solid 1px #303030;
    color: #999 !important;
  }

  nav.navbar.navbar-inverse .dropdown .megamenu-content .col-menu.on:last-child .title {
    border-bottom: solid 1px #303030;
  }

  nav.navbar.navbar-inverse .dropdown-tabs .tab-menu > ul {
    border-top: solid 1px #303030;
  }

  nav.navbar.navbar-inverse.navbar-mobile .navbar-collapse {
    background-color: #222;
  }
}

@media (max-width: 767px) {
  nav.navbar.navbar-inverse.navbar-mobile ul.nav {
    border-top: solid 1px #222;
  }
}

@media (min-width: 1024px) {
  .bootsnav.navbar-full.no-background .navbar-toggle {
    color: #fff;
  }
}

nav.bootsnav.navbar-full .navbar-toggle i {
  font-size: 18px;
  color: #9a9a9a;
  font-weight: bold;
  transition: .3s ease;
}

.navbar-toggle-txt {
  font-size: 13px;
  position: relative;
  top: -3px;
  font-weight: bold;
  color: #9a9a9a;
  transition: .3s ease;
}

nav.bootsnav.navbar-full .navbar-toggle:hover i, 
nav.bootsnav.navbar-full .navbar-toggle:hover span {
  color: #222;
  transition: .3s ease;
}

.top-search .input-group .form-control::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
  color: #666666;
  opacity: 1;
}

.top-search .input-group .form-control::-moz-placeholder {
    /* Firefox 19+ */
  color: #666666;
  opacity: 1;
}

.top-search .input-group .form-control::-ms-input-placeholder {
    /* IE 10+ */
  color: #666666;
  opacity: 1;
}

.top-search .input-group .form-control::-moz-placeholder {
    /* Firefox 18- */
  color: #666666;
  opacity: 1;
}

@media only screen and (max-width: 767px) {

  nav.navbar.bootsnav.navbar-fixed.nav-box.no-background {
    left: 0;
    top: 0 !important;
    width: 100%;
  }
}

body.wrap-nav-sidebar {
  height: auto !important;
}

.attr-nav.button li a {
  padding: 4px 20px !important;
  margin-left: 7px;
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 0.06em;
  color: #0e2b3d;
  box-shadow: 0 0 10px #cccccc;
  border-radius: 30px;
}

.attr-nav.button li a i {
  margin-right: 3px;
  color: #086AD8;
}

.attr-nav.button li a i.fa-user {
  font-weight: 500;
}

.attr-nav > ul > li.button a {
  background: #086AD8;
  color: #ffffff;
}

.attr-nav > ul > li.button a i {
  font-weight: 500;
  margin-right: 5px;
}