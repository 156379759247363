/* .sidebar { */
    /* width: 200px;
    min-height: 100vh;
    background-color: #343a40; Dark background color */
  /* } */
  
  .sidebar-content {
    padding: 15px;
  }
  
  .nav-link {
    color: white;
    padding: 10px;
    transition: color 0.3s ease; /* Smooth transition for hover effect */
  }
  
  .nav-link:hover {
    color: black !important; /* Text color on hover */
    background-color: #f8f9fa; /* Optional: change background on hover */
  }
  
  .nav-link.active {
    background-color: #495057;
    font-weight: bold;
  }
  
  .collapse {
    display: none;
  }
  
  .collapse.show {
    display: block;
  }
  
