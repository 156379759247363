.image-container {
  position: relative;
  overflow: hidden; /* Ensures image doesn't overflow container */
}

.popup-image {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transform and shadow on hover */
}

.image-container:hover .popup-image {
  transform: scale(1.2); /* Make the image larger */
  position: relative; 
  z-index: 10; /* Bring the image in front of others */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Adds a shadow for popup effect */
}
